import styled from 'styled-components';
import { colors } from '../../../assets/variables';
export const CheckboxControl = styled.div`
  flex: 0 0 100%;
  background: white;
  overflow: hidden;
  margin-bottom: 6px;
  min-height: 65px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid ${colors.primary};
  border-radius: 10px;
  background: none;
  max-width: 400px;
  margin: 0px auto 8px auto;
  width: 100%;
  .controls {
    text-align: left;
    /* padding: 16px; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }
`;
const SelectOption = styled.select`
  min-height: 41px;
  padding: 0 8px;
  width: 100%;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
`;
const Label = styled.label`
  font-size: 13px;
  font-weight: 600;
  color: ${colors.primary};
  margin-left: 16px;
`;
const Item = styled.div`
  padding: 16px;
  font-weight: 400;
  background: white;
  text-align: left;
  padding: 16px 16px 16px 48px;
  &:hover {
    background: ${colors.lightGray};
  }
  &.active {
    background: ${colors.tertiary};
  }
`;
export default { CheckboxControl, SelectOption, Label, Item };
