import styled, { css } from 'styled-components';
import { colors } from '../../../assets/variables';
interface IndicatorMinusProps {
  disabled: boolean;
}
export const Wraper = styled.div`
  flex: 0 0 100%;
  margin: 8px 0;
  border-top: 0;
  ~ ${() => Wraper} {
    margin-top: 50px;
  }
  ${props => {
    if (props.grouped) {
      return counterStyles;
    }
  }}
`;
export const Indicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.button`
  border: none;
  overflow: visible;
  background: ${colors.baseLight};
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  border-radius: 30px;
  margin: 0;
  width: 36px;
  height: 36px;
  border: 2px solid #d7f1ff;
  padding: 0;
  color: white;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
export const CenterCore = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.disabled ? '#d7f1ff' : colors.action)};
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const IndicatorNumber = styled.div`
  font-size: 36px;
  line-height: 1.1;
  font-weight: 700;
  padding: 0 8px;
  font-family: 'Open sans', sans-serif;
  color: ${colors.primary};
  min-width: 90px;
`;
export const Image = styled.img`
  margin-bottom: -50px;
  width: 150px;
  height: 150px;
`;
export const Desc = styled.div`
  margin-top: 10px;
`;
const counterStyles = css`
  margin-left: auto;
  align-items: center;
  display: flex;
  color: #0b2641;
  font-size: 25px;
  font-weight: 700;
  ${Desc} {
    order: 1;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* number of lines to show */
    margin-top: 0;
    padding-left: 10px;
  }
  ${Indicator} {
    order: 2;
    margin-left: auto;
    align-items: center;
  }
  ${IndicatorNumber} {
    font-size: 35px;
  }
  ~ ${() => Wraper} {
    margin-top: 0;
    border-top: 1px solid black;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .desc {
    min-width: 200px;
    font-size: 13px;
    line-height: 1.1;
    @media screen and (max-width: 600px) {
      min-width: 150px;
      min-height: 30px;
    }
  }
`;
const Total = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${colors.primary};
`;
export default { Item, Image, Desc, Wraper, Indicator, Button, IndicatorNumber, CenterCore, Total };
