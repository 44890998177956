import React from 'react';
import Styled from './Summary.style';
import Input from '../../FromInput';
import * as _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Reaptcha from 'reaptcha';
import { Loader, Checkbox, Select, Button } from '@savewatersavemoney/swsm-ui';
import { getVariable } from '../../../helpers';
import Typography from '../../Typography';
import { seConstants } from '../../../constants';
import i18next from 'i18next';
import { PageFreeProductsReducer } from '../../../interfaces';
import { colors } from '../../../assets/variables';

(window as any).recaptchaOptions = {
  useRecaptchaNet: true,
};

interface SummaryProps {
  swsmLocation: any;
  postUserData: Function;
  setQuestionHeight: Function;
  containerHeight: number;
  answers: any;
  modalShow: Function;
  page: PageFreeProductsReducer;
  answersStatus: {
    loading: boolean;
    done: boolean;
    error: any;
  };
  user: {
    status: {
      loading: boolean;
      done: boolean;
      error: any;
    };
    data: null | {};
  };
  qualified: boolean;
  getAddresses: Function;
  data: any;
  userInfo: object;
  qaProgress: object;
  postProgress: Function;
}

class Summary extends React.Component<SummaryProps, any> {
  private QuestionRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const clientHeight = _.get(this, 'QuestionRef.current.clientHeight', 0);
    const getPostcode = _.get(this, 'props.qaProgress.postcode');
    const requiredKeys = _.get(this, 'props.swsmLocation.data.users_street_address');
    if (requiredKeys === 'required_on_registration') {
      this.props.getAddresses(getPostcode);
    }
    if (this.props.containerHeight !== clientHeight) {
      this.props.setQuestionHeight(clientHeight);
    }
  }

  componentDidUpdate() {
    const clientHeight = _.get(this, 'QuestionRef.current.clientHeight', 0);
    if (this.props.containerHeight !== clientHeight) {
      this.props.setQuestionHeight(clientHeight);
    }
  }

  handleUserDataAsync = () => {
    const { user, postUserData, answers, answersStatus, qaProgress, postProgress } = this.props;
    const { text } = this.props.data;
    const country = getVariable('client', 'waterClient', 'aus');
    const renderUrl =
      country === 'usa' || country === 'aus' ? '/#/terms-and-conditions' : '/#/end-user-license-agreement';
    const { defaultLanguage } = this.props.swsmLocation.data;
    const skipRecaptcha = process.env.REACT_APP_SKIP_RECAPTCHA === 'true';

    const textWithBreaks = text.replace(/\\n/g, '<br />');
    const languageData = localStorage.getItem('i18nextLng');
    const addressList = _.get(this, 'props.page.addresses.data');
    const requiredKeys = _.get(this, 'props.swsmLocation.data.users_street_address');
    const addressOptions: any = () => {
      const addressList = _.map(this.props.page.addresses.data, (el: any) => {
        return {
          value: el.id,
          label: el.displayname,
        };
      });
      if (addressList.length === 0) {
        return [];
      }
      return addressList;
    };

    const RegisterSchema = Yup.object().shape({
      agree_to_terms: Yup.boolean().oneOf([true], i18next.t('portal.general_form_errors.required')),
      email: Yup.string()
        .email(i18next.t('portal.general_form_errors.email_not_valid'))
        .required(i18next.t('portal.general_form_errors.required')),
      recaptchaResponse: Yup.string().required('reCAPTCHA is required'),
      address: Yup.string().when([], {
        is: () => requiredKeys === 'required_on_registration',
        then: Yup.string().required(i18next.t('portal.general_form_errors.required')),
        otherwise: Yup.string(),
      }),
    });

    const stringArray = i18next.t('questions.summary.end_user').split('-');
    const selectAddress = i18next.t('portal.free_products.checkout.select_address');
    return (
      <Formik
        initialValues={{
          email: '',
          address: '',
          agree_to_terms: false,
          agree_to_messaging: false,
          recaptchaResponse: skipRecaptcha ? 'test123' : '',
        }}
        validationSchema={RegisterSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { postcode } = this.props.swsmLocation.data;
          const waterclient = this.props.swsmLocation.data.water_client;
          let siteUrl = `${this.props.swsmLocation.data.site_url}/#/myuse`;

          const siteUrlFromSettings = getVariable('url', 'portal');
          if (siteUrlFromSettings) {
            siteUrl = `${siteUrlFromSettings}/#/myuse`;
          }
          const selectedAddress: any = _.find(addressList, ['id', parseInt(values.address, 10)]);
          const requireData = {
            ...values,
            postcode,
            propertyName: selectedAddress && selectedAddress.displayname,
            udprn: selectedAddress && selectedAddress.udprn,
            street: selectedAddress && selectedAddress.street,
            town: selectedAddress && selectedAddress.town,
            waterclient,
            language: languageData,
          };
          const dataToSubmit = {
            ...values,
            postcode,
            waterclient,
            language: languageData,
          };

          postUserData(requiredKeys === 'required_on_registration' ? requireData : dataToSubmit, answers, siteUrl);
          postProgress(qaProgress, seConstants.COMPLETE_TRACKING);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <div>
            <p className="GWF-description" dangerouslySetInnerHTML={{ __html: i18next.t(textWithBreaks) }} />
            <Styled.Form onSubmit={handleSubmit}>
              <Styled.FormGroup>
                <label style={{ marginBottom: '20px', fontSize: '14px', fontWeight: 700 }} htmlFor="email">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <Input
                  style={{ width: '100%' }}
                  id="email"
                  name="email"
                  placeholder={i18next.t('questions.summary.email_placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className="form-control"
                  type="email"
                />
                {errors.email && touched.email && <Styled.ErrorInline>{errors.email}</Styled.ErrorInline>}
              </Styled.FormGroup>
              {requiredKeys === 'required_on_registration' ? (
                <Styled.FormGroup>
                  <Select
                    style={{ border: '2px solid black', borderRadius: '6px' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    aria-label={selectAddress}
                    name="address"
                    data-test="select-address"
                    placeholder={selectAddress}
                    options={addressOptions()}
                    error={errors.address && touched.address ? errors.address : undefined}
                  />
                </Styled.FormGroup>
              ) : null}{' '}
              <Styled.FormGroup>
                <Checkbox
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agree_to_terms}
                  checked={values.agree_to_terms}
                  checkBackground={colors.primary}
                  type="checkbox"
                  id="agree_to_terms"
                  name="agree_to_terms"
                  aria-label="I agree to End User License Agreement and Privacy Policy"
                >
                  {stringArray[0] + ' '}
                  <a
                    style={{ color: '#0b2641', fontWeight: 700 }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${document.location.origin}${renderUrl}`}
                  >
                    {stringArray[1]}
                  </a>{' '}
                  {stringArray[2]}
                  <a
                    style={{ color: '#0b2641', fontWeight: 700 }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${document.location.origin}/#/privacy`}
                  >
                    {stringArray[3]}
                  </a>{' '}
                  .
                </Checkbox>
                {errors.agree_to_terms && touched.agree_to_terms && (
                  <Styled.ErrorInline>{errors.agree_to_terms}</Styled.ErrorInline>
                )}
              </Styled.FormGroup>
              <Styled.FormGroup>
                <Checkbox
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agree_to_messaging}
                  checked={values.agree_to_messaging}
                  checkBackground={colors.primary}
                  type="checkbox"
                  id="agree_to_messaging"
                  name="agree_to_messaging"
                  aria-label="Yes, I wish to receive information to help reduce my water use"
                >
                  {i18next.t('questions.summary.receive_updates')}
                </Checkbox>
                {errors.agree_to_messaging && touched.agree_to_messaging && (
                  <Styled.ErrorInline>{errors.agree_to_messaging}</Styled.ErrorInline>
                )}
              </Styled.FormGroup>
              {skipRecaptcha ? null : (
                <Styled.FormGroup style={{ minHeight: '78px' }}>
                  <Reaptcha
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
                    onVerify={response => setFieldValue('recaptchaResponse', response)}
                    onExpire={() => setFieldValue('recaptchaResponse', '')}
                  />
                  {errors.recaptchaResponse && touched.recaptchaResponse && (
                    <Styled.ErrorInline>{errors.recaptchaResponse}</Styled.ErrorInline>
                  )}
                </Styled.FormGroup>
              )}
              {user.status.error ? <Styled.Error>{user.status.error}</Styled.Error> : null}
              {answersStatus.error ? <Styled.Error>{answersStatus.error}</Styled.Error> : null}
              <Styled.ButtonsGroup>
                <Styled.ButtonStyle
                  disabled={user.status.loading}
                  type="submit"
                  style={{ padding: '16px', marginTop: '20px', textAlign: 'center' }}
                  id="submit-summary"
                >
                  {user.status.error || answersStatus.error
                    ? i18next.t('questions.summary.try_again')
                    : i18next.t('questions.summary.view_results')}{' '}
                  {user.status.loading ? <Loader inline /> : null}
                </Styled.ButtonStyle>
              </Styled.ButtonsGroup>
              {defaultLanguage === 'en-AU' || defaultLanguage === 'en-US' ? null : (
                <Styled.ButtonsGroup>
                  <i style={{ fontSize: '14px', textAlign: 'center', marginTop: '6px' }}>
                    {i18next.t('questions.summary.bottom_disclaimer')}
                  </i>
                </Styled.ButtonsGroup>
              )}
            </Styled.Form>
          </div>
        )}
      </Formik>
    );
  };

  handleProcess = () => {
    const { user, answersStatus } = this.props;

    if (user.status.done && answersStatus.done) {
      return (
        <div>
          Getting your results...
          <Loader inline />
        </div>
      );
    }

    if (user.status.error) {
      return this.handleUserDataAsync();
    }
    if (user.status.loading && !user.status.done) {
      return (
        <div>
          Processing user data...
          <Loader inline />
        </div>
      );
    }

    if (answersStatus.error) {
      return this.handleUserDataAsync();
    }
    if (answersStatus.loading && !answersStatus.done) {
      return (
        <div>
          Processing user answers...
          <Loader inline />
        </div>
      );
    }

    return this.handleUserDataAsync();
  };

  render() {
    const { title } = this.props.data;
    const { user } = this.props;
    const title_key = i18next.t(title);
    const isDesktop = window.innerWidth > 800;
    const refreshIcon = (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21 10V3L18.36 5.64C16.74 4.01 14.49 3 12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12H19C19 15.86 15.86 19 12 19C8.14 19 5 15.86 5 12C5 8.14 8.14 5 12 5C13.93 5 15.68 5.79 16.95 7.05L14 10H21Z"
          fill="#25B3EB"
        />
      </svg>
    );
    return (
      <Styled.Wrap className="SummaryContainer" ref={this.QuestionRef}>
        <Styled.BoxTop>
          <Typography
            size="3"
            style={{ marginBottom: '8px', textAlign: 'center' }}
            text={i18next.t('questions.summary.qa_results').toString()}
          />
          <Styled.BoxWrap>
            <Styled.BoxText style={{ fontSize: '14px', marginBottom: '0px', marginTop: '0px' }}>
              {i18next.t('questions.summary.potential_savings')}
            </Styled.BoxText>
            <Styled.BoxSavings />
          </Styled.BoxWrap>
          <Styled.BoxWrap>
            <Styled.BoxText style={{ fontSize: '14px', marginBottom: '0px', marginTop: '0px' }}>
              {i18next.t('questions.summary.actual_use')}
            </Styled.BoxText>
            <Styled.BoxUse />
          </Styled.BoxWrap>
        </Styled.BoxTop>
        <Typography size="3" text={title_key} />
        {this.handleProcess()}
        {user.status.loading && !user.status.done ? null : (
          <Styled.Restart isDesktop={isDesktop}>
            <div data-test="start-again" style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                aria-label="Start again"
                style={{ fontWeight: 700, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                appearance="TRANSPARENT"
                onClick={() => this.props.modalShow('MODAL_RESET')}
              >
                {refreshIcon} {i18next.t('questions.reset_progress_buttontext')}
              </Button>
            </div>
          </Styled.Restart>
        )}
      </Styled.Wrap>
    );
  }
}

export default Summary;
