import React from 'react';
import Card from '../../Card';
import * as _ from 'lodash';
import Styled from './PageConsumption.style';
import { Typography } from './../../index';
import { Row } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';

const ConsumptionChart: React.FC<any> = props => {
  const { isDesktop, data, defaultLanguage, length } = props;
  const [display, setDisplay] = React.useState('savings');
  const [t] = useTranslation('common');

  const renderCards = data => {
    return (
      <Styled.Column key={data.id} xs={12} sm={12} md={12} lg={6} style={{ marginBottom: '16px' }}>
        <Card isDesktop={isDesktop} {...data} displayMoney={display} defaultLanguage={defaultLanguage} />
      </Styled.Column>
    );
  };

  const renderCharts = () => {
    const title = t(data.title);
    return length >= 2 ? (
      <Styled.Wrap key={data.id}>
        <Typography
          size="2"
          text={title}
          weight={700}
          style={{ marginBottom: data.id === 5 || data.id === 6 ? '32px' : '8px' }}
        />
        {data.id === 5 || data.id === 6 ? null : renderDataSet()}
        <Row>{_.map(data.components, el => renderCards(el))}</Row>
      </Styled.Wrap>
    ) : (
      <Styled.Wrap key={data.id}>
        <Row>{_.map(data.components, el => renderCards(el))}</Row>
      </Styled.Wrap>
    );
  };

  const text_selector_title = t('portal.my_use.chart_selector_title');
  const text_disclaimer = t('portal.my_use.chart_disclaimer');

  const renderDataSet = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '16px' }}>
        <Typography
          size="p"
          text={text_selector_title}
          style={{ marginBottom: '8px', color: '#174489', fontSize: '12px' }}
        />
        <Styled.ToggleWrap>
          <Styled.Item className={display === 'savings' ? 'active' : 'inactive'} onClick={() => setDisplay('savings')}>
            {defaultLanguage === 'en-US'
              ? t('portal.my_use.chart_selector_option_gallon').toUpperCase()
              : t('portal.my_use.chart_selector_option_litre').toUpperCase()}{' '}
          </Styled.Item>
          <Styled.Item className={display === 'cost' ? 'active' : 'inactive'} onClick={() => setDisplay('cost')}>
            {t('portal.my_use.chart_selector_option_cost').toUpperCase()}
          </Styled.Item>
        </Styled.ToggleWrap>
        {display === 'cost' && <Typography size="6" text={text_disclaimer} style={{ marginBottom: 0 }} />}
      </div>
    );
  };

  return <React.Fragment>{renderCharts()}</React.Fragment>;
};

export default ConsumptionChart;
