import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.div`
  background: ${colors.tertiary};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
`;
const BannerWrap = styled.div`
  background: ${colors.tertiary};
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  min-height: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${props => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const BannerImage = styled.div`
  background: url('https://cdn.getwaterfit.co.uk/static/files/images/waternight.webp');
  background-size: cover;
  display: flex;
  flex: 1 0 0px;
  height: auto;
  min-height: 300px;
  background-position-y: 20px;
`;

const BannerButton = styled.a`
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-underline-offset: 8px;
  -webkit-text-decoration-color: #25b3eb;
  text-decoration-color: #25b3eb;
  -webkit-text-decoration-thickness: 2px;
  text-decoration-thickness: 2px;
  min-height: 35px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  text-transform: none;
  color: ${colors.primary};
  margin-top: auto;
`;
const BannerCopy = styled.div`
  flex: 1;
  flex-basis: 16%;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  h3 {
    margin-top: auto;
  }
`;
const EnergyLabelContainer = styled.div`
  overflow: hidden;
  position: relative;
  overflow: visible;
`;

const EnergyLabelBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  position: relative;
  margin-bottom: 4px;
  height: 40px;
  background-color: ${props => props.color};
  width: ${props => props.width}%;

  &::after {
    content: '';
    position: absolute;
    right: -40px; /* Arrow will be outside the bar, but still visible */
    top: 0; /* Align the arrow to the top */

    border-style: solid;
    border-width: 20px;
    border-color: transparent transparent transparent ${props => props.color};
  }
`;

const EnergyLabelGrade = styled.span`
  position: absolute;
  left: 10px;
  font-size: 0.8rem;
`;

const MeLabelBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  position: relative;
  height: 40px;
  background-color: ${props => props.color};
  width: ${props => props.width}%;

  &::before {
    content: '';
    position: absolute;
    left: -40px; /* Arrow will be outside the bar, but still visible */
    top: 0; /* Align the arrow to the top */

    border-style: solid;
    border-width: 20px;
    border-color: transparent ${props => props.color} transparent transparent;
  }
`;
const MeContainer = styled.div`
  overflow: hidden;
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: flex-end;
  text-align: right;
`;

const NationalLine = styled.div`
  position: absolute;
  width: 100%;
  z-index: 0;
  hr {
    border: 1px solid #c1bdbd;
  }
  p {
    margin: 0px;
    color: #888888;
    text-align: center;
    font-size: 12px;
    padding-bottom: 0px;
    line-height: 0px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
`;

const MeLabelGrade = styled.span`
  position: absolute;
  right: 10px;
  font-size: 0.8rem;
`;

const desktopStyles = css`
  flex-direction: row;
  ${BannerImage} {
    background-size: contain;
    min-height: auto;
  }
`;
export default {
  Wrap,
  EnergyLabelBar,
  EnergyLabelGrade,
  MeLabelBar,
  EnergyLabelContainer,
  MeContainer,
  NationalLine,
  TextContainer,
  MeLabelGrade,
  BannerWrap,
  BannerImage,
  BannerCopy,
  BannerButton,
};
