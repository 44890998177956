import React from 'react';
import { Tile, Icon } from '@savewatersavemoney/swsm-ui';
import { Col, Row } from 'react-styled-flexboxgrid';
import history from '../../../history';
import { renderCdnUrl } from '../../../helpers';
import Typography from '../../Typography';
import * as _ from 'lodash';
import { Loader } from '../../index';
import { useTranslation } from 'react-i18next';

const PageOverviewBonusChallenges: React.FC<any> = props => {
  const { challenges, bonusChallenges, isDesktop, swsmInternal, clientSlug } = props;
  const [t] = useTranslation('common');
  const text_keys = {
    app_download_title: t('portal.easy_wins.app_download_title'),
    app_download_subtitle: t('portal.easy_wins.app_download_subtitle'),
    text_free_offers: t('portal.home.free_offer_title'),
  };
  if (challenges.bonusChallengesStatus.loading) {
    return (
      <div style={{ position: 'relative', height: '100px' }}>
        <Loader />
      </div>
    );
  }

  return (
    <Row>
      {_.map(bonusChallenges, (el, index) => {
        return (
          <Col
            xs={12}
            sm={12}
            md={12}
            key={index}
            style={{ marginBottom: !swsmInternal && clientSlug === 'wessex' ? '32px' : '0px' }}
          >
            <Tile
              title={t(el.title)}
              description={t(el.description)}
              isDesktop={isDesktop}
              icon={el.icon}
              onButtonClick={() => {
                history.push(el.buttonPath);
              }}
              buttonText={t(el.buttonText)}
              imageUrl={renderCdnUrl(`${el.image}`)}
              isPopup={el.internalType === 'app'}
            >
              {el.internalType === 'app' ? (
                <>
                  <Icon size={isDesktop ? 100 : 75} icon="downloadApp" style={{ margin: '0 0 8px 0' }} />
                  <Typography size="3" text={text_keys.app_download_title} style={{ margin: '0 0 8px 0' }} />
                  <Typography
                    size="p"
                    text={text_keys.app_download_title}
                    style={{ margin: isDesktop ? '0 0 24px 0' : '0 0 16px 0' }}
                  />
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=uk.co.getwaterfit.app&gl=GB"
                      target="_blank"
                      aria-label="download the app"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ width: 130, marginRight: '16px' }}
                        alt={'googleplaystore'}
                        src={renderCdnUrl('images/appstorebadges/googleplaystore.svg')}
                      />
                    </a>
                    <a
                      style={{ width: 152 }}
                      href="https://apps.apple.com/gb/app/getwaterfit/id1522291409"
                      target="_blank"
                      aria-label="download the app"
                      rel="noopener noreferrer"
                    >
                      <img alt={'appleappstore'} src={renderCdnUrl('images/appstorebadges/appStore.svg')} />
                    </a>
                  </div>{' '}
                </>
              ) : null}
            </Tile>
          </Col>
        );
      })}
    </Row>
  );
};

export default PageOverviewBonusChallenges;
