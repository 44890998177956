/* eslint-disable no-nested-ternary */
import React from 'react';
import Styled from './UnifiedLabel.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import { Icon } from '@savewatersavemoney/swsm-ui';
import Typography from '../Typography';
import history from '../../history';
import { getVariable } from '../../helpers';
import { useTranslation } from 'react-i18next';
interface UnifiedLabelProps {
  data: any;
  defaultLang: string;
}

const UnifiedLabel: React.FC<UnifiedLabelProps> = props => {
  const { data, defaultLang } = props;
  const country = getVariable('client', 'waterClient', 'aus');
  const nationalData = _.get(data, 'data.nationalWaterUsePerPerson');
  const userData = _.get(data, 'data.userWaterUsePerPerson');
  const numberPeople = _.get(data, 'data.numberOfOccupants');
  const [t] = useTranslation('common');
  const renderNationalData =
    country === 'usa' ? (nationalData * 2.6 * 365) / 12 : defaultLang === 'en-CA' ? nationalData : nationalData;
  const renderUserData =
    country === 'usa' ? (userData * numberPeople * 365) / 12 : defaultLang === 'en-CA' ? userData : userData;

  const labels = [
    { grade: 50, color: '#4CAF50' }, // Green
    { grade: 125, color: '#CDDC39' }, // Lime
    { grade: 200, color: '#FFC107' }, // Amber
    { grade: 275, color: '#FF9800' }, // Orange
    { grade: 350, color: '#F44336' }, // Red
  ];

  const labelsUsa = [
    { grade: 1250 * 2.6, color: '#4CAF50' }, // Green
    { grade: 2500 * 2.6, color: '#CDDC39' }, // Lime
    { grade: 3250 * 2.6, color: '#FFC107' }, // Amber
    { grade: 4000 * 2.6, color: '#FF9800' }, // Orange
    { grade: 5000 * 2.6, color: '#F44336' }, // Red
  ];

  const labelsCanada = [
    { grade: Math.round(1250 * 2.6 * 3.785411784), color: '#4CAF50' }, // Green
    { grade: Math.round(2500 * 2.6 * 3.785411784), color: '#CDDC39' }, // Lime
    { grade: Math.round(3250 * 2.6 * 3.785411784), color: '#FFC107' }, // Amber
    { grade: Math.round(4000 * 2.6 * 3.785411784), color: '#FF9800' }, // Orange
    { grade: Math.round(5000 * 2.6 * 3.785411784), color: '#F44336' }, // Red
  ];
  const renderLabel =
    country === 'usa' && defaultLang !== 'en-CA'
      ? labelsUsa
      : country === 'usa' && defaultLang === 'en-CA'
        ? labelsCanada
        : labels;

  const width = [35, 52, 72, 85, 100];

  const lineValueUse =
    ((renderNationalData - renderLabel[0].grade) * 200) / (renderLabel[4].grade - renderLabel[0].grade);

  const barValueUse = ((renderUserData - renderLabel[0].grade) * 177) / (renderLabel[4].grade - renderLabel[0].grade);

  const colorChange = () => {
    if (renderUserData <= renderLabel[0].grade) {
      return '#4CAF50';
    }
    else if (renderUserData <= renderLabel[1].grade && renderUserData > renderLabel[0].grade) {
      return '#CDDC39';
    }
    else if (renderUserData <= renderLabel[2].grade && renderUserData > renderLabel[1].grade) {
      return '#FFC107';
    }
    else if (renderUserData <= renderLabel[3].grade && renderUserData > renderLabel[2].grade) {
      return '#FF9800';
    }
    else if (renderUserData >= renderLabel[3].grade) {
      return '#F44336';
    }
  };

  const title = t('page.overview.unified-label.title');
  const subTitle = t('page.overview.unified-label.subtitle');
  const nationalAvg = t('page.overview.unified-label.national-avg');
  const you = t('page.overview.unified-label.you');

  return (
    <Styled.Wrap>
      <Styled.TextContainer onClick={() => history.push('/myuse')}>
        <Icon icon="waterCoins" size={50} />
        <div style={{ margin: '0px 12px' }}>
          <Typography size="4" text={title} style={{ marginBottom: '0px', textDecoration: 'underline' }} />
          <Typography size="p" text={subTitle} style={{ fontSize: '14px' }} />
        </div>
      </Styled.TextContainer>
      <div style={{ position: 'relative' }}>
        <Styled.NationalLine
          style={{
            top:
              renderNationalData > renderLabel[4].grade
                ? '200px'
                : renderNationalData < renderLabel[0].grade
                  ? '0px'
                  : `${lineValueUse}px`,
          }}
        >
          <p>{nationalAvg}</p>
          <hr />
        </Styled.NationalLine>

        <Row>
          <Col md={6} xs={6} sm={6}>
            <Styled.EnergyLabelContainer>
              {renderLabel.map((label, index) => (
                <Styled.EnergyLabelBar key={label.grade} color={label.color} width={width[index]}>
                  <Styled.EnergyLabelGrade>
                    {label.grade}
                    {country === 'usa' && defaultLang !== 'en-CA'
                      ? 'gal'
                      : country === 'usa' && defaultLang === 'en-CA'
                        ? 'L'
                        : 'L'}
                  </Styled.EnergyLabelGrade>
                </Styled.EnergyLabelBar>
              ))}
            </Styled.EnergyLabelContainer>
          </Col>
          <Col md={6} xs={6} sm={6}>
            <Styled.MeContainer>
              <Styled.MeLabelBar
                color={colorChange()}
                width={65}
                style={{
                  marginTop:
                    renderUserData > renderLabel[4].grade
                      ? '177px'
                      : renderUserData < renderLabel[0].grade
                        ? '0px'
                        : barValueUse,
                }}
              >
                <Styled.MeLabelGrade>
                  {you}: {renderUserData && renderUserData.toFixed(0)}
                  {country === 'usa' && defaultLang !== 'en-CA'
                    ? 'gal'
                    : country === 'usa' && defaultLang === 'en-CA'
                      ? 'L'
                      : 'L'}
                </Styled.MeLabelGrade>
              </Styled.MeLabelBar>
            </Styled.MeContainer>
          </Col>
        </Row>
      </div>
    </Styled.Wrap>
  );
};
export default UnifiedLabel;
