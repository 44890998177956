import styled from 'styled-components';
import { colors } from '../../../assets/variables';

const Wraper = styled.div`
  max-width: 375px;
  margin: auto;
`;

const Row = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: flex-end;
  & > label {
    border: 2px solid ${colors.primary};
    border-radius: 10px;
    background: none;
    margin-bottom: 8px;
  }

  & > img + label {
    min-width: 280px;
    @media screen and (max-width: 414px) {
      min-width: 200px;
    }
  }
  &:first-child > img + label {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child > img + label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Image = styled.img`
  margin-top: 20px;
  height: 80px;
  width: 80px;
`;

export default {
  Wraper,
  Row,
  Image,
};
